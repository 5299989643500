<template>
  <div id="schedule" class="schedule">
    <div
      class="container-fluid content pl-0 pr-0 pb-0 padding-top-responsive"
    >
      <div class="col-12">
        <div class="row p-0 m-0 row-action-schedule">
          <v-col cols="4" class="col-xl-s6">
            <div class="session-ktc-inside" id="responsive_Court">
              <div class="row pt-1">
                <div class="col-5">
                  <label class="yard-title">INDOOR SHOW COURT</label>
                  <div class="">
                    <button
                      block
                      class="btn btn-yard"
                      v-for="(item, index) in courts_data.indoor_show_court"
                      :key="index"
                      :class="{
                        'btn-yard-active':
                          activeCourt && item.id == activeCourt.id,
                        'mgl-8': index > 0,
                        'disable-schedule':
                          activeCourt && scheduleData && scheduleData.id,
                      }"
                      @click="changeActiveCourt(item)"
                      :style="{ width: width_btn_court + 'px' }"
                    >
                      <span>{{ item.name }}</span>
                    </button>
                  </div>
                </div>

                <div class="col-7 responsive-col-7">
                  <label class="yard-title">INDOOR PRACTICE COURT</label>
                  <div class="">
                    <button
                      block
                      class="btn btn-yard"
                      v-for="(item, index) in courts_data.indoor_practice_court"
                      :key="index"
                      :class="{
                        'btn-yard-active':
                          activeCourt && item.id == activeCourt.id,
                        'mgl-8': index > 0,
                        'disable-schedule':
                          activeCourt && scheduleData && scheduleData.id,
                      }"
                      @click="changeActiveCourt(item)"
                      :style="{ width: width_btn_court + 'px' }"
                    >
                      <span>{{ item.name }}</span>
                    </button>
                  </div>
                </div>

                <div class="col-7 col-7-custom-schedule pr-0">
                  <label class="yard-title">OUTDOOR TENNIS COURT</label>
                  <div class="row m-0">
                    <button
                      block
                      class="btn btn-yard"
                      :class="{
                        'btn-yard-active':
                          activeCourt &&
                          courts_data.outdoor_tennis_court[0].id ==
                            activeCourt.id,
                        'disable-schedule':
                          activeCourt && scheduleData && scheduleData.id,
                      }"
                      @click="
                        changeActiveCourt(courts_data.outdoor_tennis_court[0])
                      "
                      :style="{ width: width_btn_court + 'px' }"
                    >
                      <span>{{
                        courts_data.outdoor_tennis_court[0].name
                      }}</span>
                    </button>
                    <button
                      block
                      class="btn btn-yard mgl-8"
                      :class="{
                        'btn-yard-active':
                          activeCourt &&
                          courts_data.outdoor_tennis_court[1].id ==
                            activeCourt.id,
                        'disable-schedule':
                          activeCourt && scheduleData && scheduleData.id,
                      }"
                      @click="
                        changeActiveCourt(courts_data.outdoor_tennis_court[1])
                      "
                      :style="{ width: width_btn_court + 'px' }"
                    >
                      <span>{{
                        courts_data.outdoor_tennis_court[1].name
                      }}</span>
                    </button>
                    <button
                      block
                      class="btn btn-yard mgl-8"
                      :class="{
                        'btn-yard-active':
                          activeCourt &&
                          courts_data.outdoor_tennis_court[2].id ==
                            activeCourt.id,
                        'disable-schedule':
                          activeCourt && scheduleData && scheduleData.id,
                      }"
                      @click="
                        changeActiveCourt(courts_data.outdoor_tennis_court[2])
                      "
                      :style="{ width: width_btn_court + 'px' }"
                    >
                      <span>{{
                        courts_data.outdoor_tennis_court[2].name
                      }}</span>
                    </button>
                    <button
                      block
                      class="btn btn-yard mgl-8"
                      :class="{
                        'btn-yard-active':
                          activeCourt &&
                          courts_data.outdoor_tennis_court[3].id ==
                            activeCourt.id,
                        'disable-schedule':
                          activeCourt && scheduleData && scheduleData.id,
                      }"
                      @click="
                        changeActiveCourt(courts_data.outdoor_tennis_court[3])
                      "
                      :style="{ width: width_btn_court + 'px' }"
                    >
                      <span>{{
                        courts_data.outdoor_tennis_court[3].name
                      }}</span>
                    </button>
                  </div>
                  <div class="row m-0 mt-2">
                    <button
                      block
                      class="btn btn-yard"
                      :class="{
                        'btn-yard-active':
                          activeCourt &&
                          courts_data.outdoor_tennis_court[4].id ==
                            activeCourt.id,
                        'disable-schedule':
                          activeCourt && scheduleData && scheduleData.id,
                      }"
                      @click="
                        changeActiveCourt(courts_data.outdoor_tennis_court[4])
                      "
                      :style="{ width: width_btn_court + 'px' }"
                    >
                      <span>{{
                        courts_data.outdoor_tennis_court[4].name
                      }}</span>
                    </button>
                    <button
                      block
                      class="btn btn-yard mgl-8"
                      :class="{
                        'btn-yard-active':
                          activeCourt &&
                          courts_data.outdoor_tennis_court[5].id ==
                            activeCourt.id,
                        'disable-schedule':
                          activeCourt && scheduleData && scheduleData.id,
                      }"
                      @click="
                        changeActiveCourt(courts_data.outdoor_tennis_court[5])
                      "
                      :style="{ width: width_btn_court + 'px' }"
                    >
                      <span>{{
                        courts_data.outdoor_tennis_court[5].name
                      }}</span>
                    </button>
                    <button
                      block
                      class="btn btn-yard mgl-8"
                      :class="{
                        'btn-yard-active':
                          activeCourt &&
                          courts_data.outdoor_tennis_court[6].id ==
                            activeCourt.id,
                        'disable-schedule':
                          activeCourt && scheduleData && scheduleData.id,
                      }"
                      @click="
                        changeActiveCourt(courts_data.outdoor_tennis_court[6])
                      "
                      :style="{ width: width_btn_court + 'px' }"
                    >
                      <span>{{
                        courts_data.outdoor_tennis_court[6].name
                      }}</span>
                    </button>
                    <button
                      block
                      class="btn btn-yard mgl-8"
                      :class="{
                        'btn-yard-active':
                          activeCourt &&
                          courts_data.outdoor_tennis_court[7].id ==
                            activeCourt.id,
                        'disable-schedule':
                          activeCourt && scheduleData && scheduleData.id,
                      }"
                      @click="
                        changeActiveCourt(courts_data.outdoor_tennis_court[7])
                      "
                      :style="{ width: width_btn_court + 'px' }"
                    >
                      <span>{{
                        courts_data.outdoor_tennis_court[7].name
                      }}</span>
                    </button>
                  </div>
                </div>

                <div class="col-2 col-2-custom-schedule pl-0 pr-0">
                  <label class="yard-title">JUNIOR</label>
                  <div class="row m-0">
                    <button
                      block
                      class="btn btn-yard"
                      :class="{
                        'btn-yard-active':
                          activeCourt &&
                          courts_data.junior[0].id == activeCourt.id,
                        'disable-schedule':
                          activeCourt && scheduleData && scheduleData.id,
                      }"
                      @click="changeActiveCourt(courts_data.junior[0])"
                      :style="{ width: width_btn_court + 'px' }"
                    >
                      <span>{{ courts_data.junior[0].name }}</span>
                    </button>
                  </div>
                  <div class="row m-0 mt-2">
                    <button
                      block
                      class="btn btn-yard"
                      :class="{
                        'btn-yard-active':
                          activeCourt &&
                          courts_data.junior[1].id == activeCourt.id,
                        'disable-schedule':
                          activeCourt && scheduleData && scheduleData.id,
                      }"
                      @click="changeActiveCourt(courts_data.junior[1])"
                      :style="{ width: width_btn_court + 'px' }"
                    >
                      <span>{{ courts_data.junior[1].name }}</span>
                    </button>
                  </div>
                </div>
                <div class="col-3 col-3-custom-schedule pl-0">
                  <label class="yard-title">ROOFTOP COURT</label>
                  <div class="row m-0">
                    <button
                      block
                      class="btn btn-yard"
                      :class="{
                        'btn-yard-active':
                          activeCourt &&
                          courts_data.rooftop_court[0].id == activeCourt.id,
                        'disable-schedule':
                          activeCourt && scheduleData && scheduleData.id,
                      }"
                      @click="changeActiveCourt(courts_data.rooftop_court[0])"
                      :style="{ width: width_btn_court + 'px' }"
                    >
                      <span>{{ courts_data.rooftop_court[0].name }}</span>
                    </button>
                    <button
                      block
                      class="btn btn-yard mgl-8"
                      :class="{
                        'btn-yard-active':
                          activeCourt &&
                          courts_data.rooftop_court[1].id == activeCourt.id,
                        'disable-schedule':
                          activeCourt && scheduleData && scheduleData.id,
                      }"
                      @click="changeActiveCourt(courts_data.rooftop_court[1])"
                      :style="{ width: width_btn_court + 'px' }"
                    >
                      <span>{{ courts_data.rooftop_court[1].name }}</span>
                    </button>
                  </div>

                  <div class="row m-0 mt-2">
                    <button
                      block
                      class="btn btn-yard"
                      :class="{
                        'btn-yard-active':
                          activeCourt &&
                          courts_data.rooftop_court[2].id == activeCourt.id,
                        'disable-schedule':
                          activeCourt && scheduleData && scheduleData.id,
                      }"
                      @click="changeActiveCourt(courts_data.rooftop_court[2])"
                      :style="{ width: width_btn_court + 'px' }"
                    >
                      <span>{{ courts_data.rooftop_court[2].name }}</span>
                    </button>
                    <button
                      block
                      class="btn btn-yard mgl-8"
                      :class="{
                        'btn-yard-active':
                          activeCourt &&
                          courts_data.rooftop_court[3].id == activeCourt.id,
                        'disable-schedule':
                          activeCourt && scheduleData && scheduleData.id,
                      }"
                      @click="changeActiveCourt(courts_data.rooftop_court[3])"
                      :style="{ width: width_btn_court + 'px' }"
                    >
                      <span>{{ courts_data.rooftop_court[3].name }}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="3" class="col-xl-s6" style="padding-left: 0px">
            <div class="session date-picker">
              <div class="session-content">
                <v-date-picker
                  ref="datepicker"
                  flat
                  no-title
                  is-double-paned
                  is-inline
                  width="auto"
                  class="date-picker"
                  first-day-of-week="0"
                  v-model="pickerDate"
                  :picker-date.sync="pickerDate"
                  type="date"
                  :min="NOW_DATE().format(DATE_FORMAT_SCHEDULE)"
                  :max="
                    NOW_DATE()
                      .add(this.MAX_MONTH_SELECT, 'months')
                      .endOf('month')
                      .format(DATE_FORMAT_SCHEDULE)
                  "
                  :disabled="
                    scheduleData &&
                    scheduleData.status &&
                    (STATUS_DISABLED_DATE_SCHEDULE.includes(scheduleData.status))
                  "
                ></v-date-picker>
              </div>
            </div>
          </v-col>
          <v-col
            cols="5"
            class="col-xl-s6 schedule-time"
            style="padding-left: 0"
          >
            <div class="row margin-schedule-top">
              <div class="col-6 pt-0 pb-0">
                <span class="yard-schedule" :class="!activeCourt || !activeCourt.name ? 'mb-2' : ''"
                  >COURT:
                  <span class="yard-schedule-current" :class="!activeCourt || !activeCourt.name ? 'vertical-bottom-court' : '' ">
                    {{ activeCourt && activeCourt.name ? activeCourt.name : '--' }}</span
                  ></span
                >
              </div>
              <div
                class="col-6 pt-0 pb-0 hour-schedule-responsive"
              >
                <p class="yard-schedule-hour mb-0">{{ nowTime }}</p>
                <p class="yard-schedule-day mb-2">{{ nowDate }}</p>
              </div>
              <div class="col-10 pt-0">
                <div class="session-ktc-inside">
                  <div
                    class="row"
                    :class="{
                      checkDisabledEdit: scheduleData && scheduleData.id,
                    }"
                  >
                    <div class="col-title-responsive col-4 pr-0 text-right m-auto padding-date-responsive">
                      <span class="schedule-label-current mt-1">DATE</span>
                    </div>
                    <div class="col-8 text-center m-auto padding-date-responsive">
                      <span class="schedule-label-current text-white mt-1"
                      :class="{
                        checkDisabledEdit: scheduleData && scheduleData.id,
                        'disabled-text': (
                          scheduleData && scheduleData.status &&
                          (scheduleData.status != 'New')
                        )
                      }"
                      >{{
                        scheduleData.date
                          ? MOMENT(scheduleData.date, "ddd DD MMM YY")
                          : MOMENT(new Date(), "ddd DD MMM YY")
                      }}</span>
                    </div>
                  </div>

                  <div
                    class="row row-info-schedule"
                    :class="{
                      checkDisabledEdit: scheduleData && scheduleData.id,
                    }"
                  >
                    <div class="col-title-responsive col-4 pr-0 text-right m-auto">
                      <span class="schedule-label-current mt-1"
                        >START TIME</span
                      >
                    </div>
                    <div class="col-8 text-center">
                      <v-menu
                        ref="menuStartTime"
                        v-model="menuStartTime"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="scheduleData.start_time"
                        transition="scale-transition"
                        offset-y
                        :disabled="disableStartTime()"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            class="input-date-picker-schedule"
                            v-model="scheduleData.start_time"
                            append-icon="mdi-clock-time-four-outline"
                            v-bind="attrs"
                            placeholder=":"
                            v-on="on"
                            :disabled="disableStartTime()"
                          >
                            <v-icon 
                                slot="append" 
                                @click="menuStartTime = !menuStartTime"
                                class="mdi-clock-time-four-outline"
                            >
                            </v-icon>
                          </v-text-field>
                        </template>
                        <v-time-picker
                          v-if="menuStartTime"
                          format="24hr"
                          v-model="scheduleData.start_time"
                          full-width
                          scrollable
                          :disabled="disableStartTime()"
                          @click:minute="$refs.menuStartTime.save(scheduleData.start_time)"
                        ></v-time-picker>
                      </v-menu>
                    </div>
                  </div>

                  <div class="row row-info-schedule">
                    <div class="col-title-responsive col-4 pr-0 text-right m-auto">
                      <span class="schedule-label-current mt-1">END TIME</span>
                    </div>
                    <div class="col-8 text-center">
                      <v-menu
                        ref="menuEndTime"
                        v-model="menuEndTime"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="scheduleData.end_time"
                        transition="scale-transition"
                        offset-y
                        :disabled="disableEndTime()"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            class="input-date-picker-schedule"
                            v-model="scheduleData.end_time"
                            append-icon="mdi-clock-time-four-outline"
                            v-bind="attrs"
                            placeholder=":"
                            v-on="on"
                            :disabled="disableEndTime()"
                          >
                            <v-icon 
                              slot="append" 
                              @click="menuEndTime = !menuEndTime"
                              class="mdi-clock-time-four-outline"
                            >
                            </v-icon>
                          </v-text-field>
                        </template>
                        <v-time-picker
                          v-if="menuEndTime"
                          format="24hr"
                          v-model="scheduleData.end_time"
                          full-width
                          :disabled="disableEndTime()"
                          scrollable
                          :min="NOW_DATE().format(HOUR_FORMAT)"
                          max="23:59"
                          @click:minute="$refs.menuEndTime.save(scheduleData.end_time)"
                        ></v-time-picker>
                      </v-menu>
                    </div>
                  </div>

                  <div class="row row-info-schedule">
                    <div class="col-title-responsive col-4 pr-0 text-right m-auto">
                      <span class="schedule-label-current mt-1">MODE</span>
                    </div>
                    <div class="col-8 text-center m-auto">
                      <v-select
                        class="custom-select-modes"
                        :items="scheduleData.modes ?? []"
                        filled
                        v-model="scheduleData.currentMode"
                        :disabled="!activeCourt || (activeCourt && scheduleData.status == 'New' && !activeNew) || scheduleData.status == 'Completed'"
                      >
                      </v-select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-2 pt-0 pl-0">
                <div class="session-ktc-inside schedule-action">
                  <div
                    class="col-12 pt-1 text-center add-schedule"
                    :class="
                      !activeNewSchedule() ? 'disable-schedule' : ''
                    "
                  >
                    <i
                      class="btn btn-add-schedule"
                      @click="createNewSchedule()"
                      title="Add Schedule"
                    ></i>
                  </div>
                  <div
                    class="col-12 pt-1 text-center save-schedule"
                    :class="
                      !activeSaveSchedule() ? 'disable-schedule' : ''
                    "
                  >
                    <i
                      class="btn btn-save-schedule"
                      title="Save Schedule"
                      @click="saveSchedule()"
                    ></i>
                  </div>
                  <div
                    class="col-12 pt-1 text-center remove-schedule"
                    :class="
                      !activeRemoveSchedule() ? 'disable-schedule' : ''
                    "
                  >
                    <i
                      class="btn btn-remove-schedule"
                      title="Remove Schedule"
                      @click="showModelRemove()"
                    ></i>
                  </div>
                  <div
                    class="col-12 pb-0 pt-1 text-center"
                    :class="!activeCloseSchedule() ? 'disable-schedule' : ''"
                  >
                    <i
                      class="btn btn-close-schedule"
                      title="Cancel Schedule"
                      @click="resetForm()"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </div>
      </div>

      <div class="col-12 pb-0">
        <div
          class="row padding-responsive"
          v-if="
            dataSchedules && !dataSchedules.error && dataSchedules.length > 0
          "
        >
          <div class="col-2-grantt-chart">
            <div class="pre-week">
              <button class="btn-pre" @click="preNextWeek(-1)"></button>
            </div>
            <v-row class="pt-1">
              <div
                v-for="item in dataSchedules"
                :key="item.label"
                class="col-12 text-center font-size-21 day-in-week"
                :style="{ height: height_row + 'px' }"
                :class="{ 'to-day-row-label': classSelectedDate(item.date) }"
              >
                {{ item.label }}
              </div>
            </v-row>
            <div class="next-week pt-2">
              <button class="btn-next" @click="preNextWeek(1)"></button>
            </div>
          </div>
          <div class="col-10-grantt-chart">
            <g-gantt-chart
              ref="gantt-chart"
              :chart-start="myChartStart"
              :chart-end="myChartEnd"
              theme="dark"
              :grid="true"
              :hide-timeaxis="false"
              :push-on-overlap="false"
              precision="day"
              locale="en"
              :row-height="height_row"
              @dblclick-bar="eventClickBarMenu($event)"
              @dragend-bar="eventDragBar($event)"
              :highlighted-hours="HIGH_LIGHT_HOUR"
            >
              <g-gantt-row
                v-for="row in dataSchedules"
                :key="row.label"
                :label="row.label"
                :bars="row.bars"
                :highlight-on-hover="true"
                bar-start="myStart"
                bar-end="myEnd"
                :class="classSelectedDate(row.date) ? 'row-grantt-today' : ''"
              >
                <template #bar-label="{ bar }">
                  <span>{{ bar.mode }}</span>
                </template>
              </g-gantt-row>
            </g-gantt-chart>
          </div>
        </div>
      </div>
    </div>
    <v-dialog
      v-model="showModalContinuate"
      max-width="700"
      persistent
      content-class="profile-confirm-dialog"
    >
      <v-card id="profile-confirm">
        <v-card-title>CONFIRM ACTION</v-card-title>
        <v-card-text
          ><v-row no-gutters>
            <v-col cols="12" style="padding-top: 6px">
              <div class="text-center">
                <span>Your changes will be discarded.</span><br>
                <span>Do you wish to continue?</span>
              </div>
            </v-col>
            <v-col cols="12" class="text-center pt-4">
              <button
                class="btn btn-secondary btn-custom"
                :ripple="false"
                @click="cancelAction()"
              >
                No
              </button>
              <button
                class="ml-4 btn btn-primary btn-custom"
                :ripple="false"
                @click="acceptAction()"
              >
                Yes
              </button>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showModalRemove"
      max-width="700"
      persistent
      content-class="profile-confirm-dialog"
    >
      <v-card id="profile-confirm">
        <v-card-title>Remove Schedule</v-card-title>
        <v-card-text
          ><v-row no-gutters>
            <v-col cols="12" style="padding-top: 6px">
              <div class="text-center" v-html="messageDialogRemoveSchedule"></div>
            </v-col>
            <v-col cols="12" class="text-center pt-2">
              <button
                class="btn btn-secondary btn-custom"
                :ripple="false"
                @click="showModalRemove = false"
              >
                No
              </button>
              <button
                class="ml-4 btn btn-primary btn-custom"
                :ripple="false"
                @click="removeSchedule"
              >
                Yes
              </button>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <ModalMessage ref="modalMessage"></ModalMessage>
  </div>
</template>

<style lang="scss" src="../../assets/css/schedule.scss"></style>

<script>
import { GGanttChart, GGanttRow } from "vue-ganttastic";
import { mapGetters } from "vuex";
import { scheduleService } from "../../services";
import courts_data_default from "@/components/courts_data";
import ModalMessage from "@/components/ModalMessage.vue";
import moment from "moment";

export default {
  name: "ScheduleManagement",
  components: {
    GGanttChart,
    GGanttRow,
    ModalMessage,
  },
  data() {
    return {
      height_row: 68,
      myChartStart: moment()
        .startOf("day")
        .format(this.DATE_TIME_HOUR_FORMAT_MOMENT),
      myChartEnd: moment()
        .add(1, "days")
        .startOf("day")
        .format(this.DATE_TIME_HOUR_FORMAT_MOMENT),
      start_date_schedule: moment().startOf("week"),
      end_date_schedule: moment().endOf("week"),
      date_between: [],
      courts_data: courts_data_default,
      courts: courts_data_default.data,
      activeCourt: null,
      scheduleData: {
        date: moment(),
        start_time: "",
        end_time: "",
        modes: [],
        currentMode: null,
        id: null,
        status: 'New',
        type: 'new'
      },
      showModalRemove: false,
      showModalContinuate: false,
      messageDialogRemoveSchedule: false,
      width_btn_court: 66,
      nowTime: "",
      nowDate: "",
      nowDateTime: moment(),
      activeNew: false,
      waitLoadingData: false,
      date: moment().format(this.DATE_FORMAT_SCHEDULE),
      groupModes: {},
      interval_nowTimes: null,
      activeClick: null,
      courtChange: null,
      dataScheduleEvent: null,
      menuStartTime: false,
      menuEndTime: false,
    };
  },
  computed: {
    ...mapGetters({ getDataSchedules: "schedules/getDataSchedules" }),
    dataSchedules: {
      get() {
        return this.getDataSchedules;
      },
      set(newData) {
        return newData;
      },
    },
    pickerDate: {
      get: function () {
        return this.date;
      },
      set: function (newDate) {
        this.date = moment(newDate).format(this.DATE_FORMAT_SCHEDULE);
        this.scheduleData.date = newDate;
        this.start_date_schedule = moment(newDate).clone().startOf("week");
        this.end_date_schedule = moment(newDate).clone().endOf("week");
        this.getListSchedules()
      },
    },
  },
  async created() {
    this.date = moment().format(this.DATE_FORMAT_SCHEDULE);
    await this.getListModes();
    await this.getListCourts();
    await this.getListSchedules();
    this.$forceUpdate();
  },
  mounted() {
    this.nowTimes();
    this.interval_nowTimes = setInterval(function () { this.nowTimes(); }.bind(this), 1000);
    this.getDimensions();
    window.addEventListener("resize", this.getDimensions);
    this.$store.dispatch("schedules/setScheduleIndexFunc", this);
  },
  destroyed() {
    clearInterval(this.interval_nowTimes);
  },
  methods: {
    disableStartTime() {
      return !this.activeCourt ||
        (this.scheduleData && this.scheduleData.status && this.scheduleData.status == 'On-going') 
        || this.scheduleData.status == 'Completed' || (this.activeCourt && this.scheduleData.status == 'New' && !this.activeNew)
    },
     disableEndTime() {
      return !this.activeCourt || (this.activeCourt && this.scheduleData.status == 'New' && !this.activeNew) || this.scheduleData.status == 'Completed'
    },
    activeNewSchedule() {
      return this.activeCourt && this.scheduleData.date && !this.scheduleData.id && this.scheduleData.status == 'New' && !this.activeNew;
    },
    activeSaveSchedule() {
      return this.activeCourt && this.scheduleData.date && this.scheduleData.status != 'Completed' && (this.scheduleData.id || (this.scheduleData.status == 'New' && this.activeNew));
    },
    activeRemoveSchedule() {
      return this.activeCourt && this.scheduleData.date && this.scheduleData.status != 'Completed' && (this.scheduleData.status == 'On-going' || this.scheduleData.status == 'Pending');
    },
    activeCloseSchedule() {
      return this.activeCourt && this.scheduleData.date && (this.scheduleData.id || (this.scheduleData.status == 'New' && this.activeNew));
    },
    classSelectedDate(dayRow) {
      return (this.pickerDate ?? moment().format(this.DATE_FORMAT_SCHEDULE)) == dayRow;
    },
    async getListModes() {
      await this.$store.dispatch("courts/getModes");
      this.groupModes = await this.handelGroupModes(
        this.$store.state.courts.modes
      );
    },
    async getListCourts() {
      await this.$store.dispatch("courts/getCourts");
      this.courts = this.$store.state.courts.courts;
      this.courts_data = await this.handelGroupCourts(
        this.groupModes,
        this.courts
      );
    },
    nowTimes() {
      this.nowDate = moment().format("ddd, DD MMM YYYY");
      this.nowTime = moment().format(this.HOUR_FORMAT);
      this.nowDateTime = moment();
    },
    changeActiveCourt(court) {
      this.courtChange = court;
      if (this.activeNew && this.activeCourt && this.activeCourt.id != court.id && this.scheduleData.date && this.scheduleData.status == 'New') {
        this.activeClick = 'court';
        this.showModalContinuate = true
      } else {
        this.setActiveCourt();
      }
    },
    async setActiveCourt() {
      this.activeCourt = this.courtChange;
      this.scheduleData.modes = this.activeCourt.modes;
      this.courtChange = null;
      await this.getListSchedules();
    },
    cancelAction() {
      this.showModalContinuate = false;
      this.courtChange = null;
      if (this.activeClick == 'schedule-drag') {
        this.revertBar(this.dataScheduleEvent);
      }
      this.activeClick = null;
      this.dataScheduleEvent = null;
    },
    revertBar(data) {
      let now = moment().startOf("day");
      let indexRow = this.dataSchedules.findIndex((item) => {
        return item.date == data.date;
      });
      if (indexRow >= 0) {
        let indexBar = this.dataSchedules[indexRow].bars.findIndex((item) => {
          return item.id == data.id;
        });
        if (indexBar >= 0) {
          this.setTimeBar(indexRow, indexBar, now, now, data.start_time, data.end_time);
        }
      }
    },
    async acceptAction() {
      if (this.activeClick == 'court') {
        await this.setActiveCourt();
      } else if (this.activeClick == 'schedule-db-click') {
        await this.clickBarMenu();
      } else if (this.activeClick == 'schedule-drag') {
        await this.stoppedDraggingBar();
      } else if (this.activeClick == 'change-page') {
        let toPath = this.$store.state.schedules.toPath;
        window.location.href = toPath ?? '/';
      }
      this.showModalContinuate = false;
    },
    preNextWeek(week) {
      let currentWeek = moment(this.start_date_schedule).add(week, "weeks");
      let max = this.NOW_DATE().clone().add(this.MAX_MONTH_SELECT, "months").endOf("month");
      let daysBetween = this.getDaysBetween(currentWeek.clone().startOf("week"), currentWeek.clone().endOf("week"));
      let check_result = daysBetween.filter((item) => { return item <= max; });
      let vm = this;
      if (check_result && check_result.length > 0) {
        vm.start_date_schedule = currentWeek.clone().startOf("week");
        vm.end_date_schedule = currentWeek.clone().endOf("week");
        vm.getListSchedules();
      }
    },
    getStyleBar(bar) {
      if (this.nowDateTime.isBetween(moment(bar.start_time), moment(bar.end_time))) {
        return { color: "#212121", backgroundColor: this.COLOR_SCHEDULE_ONGOING };
      }
      return { color: "#FFFFFF", backgroundColor: this.COLOR_SCHEDULE_PENDING };
    },
    async getListSchedules() {
      this.waitLoadingData = true;
      let data = [];
      if (this.activeCourt && this.activeCourt.id) {
        let params =  "?start_time=" + this.start_date_schedule.startOf("day").format(this.FULL_DATE_TIME_FORMAT_MOMENT) +
          "&end_time=" + this.end_date_schedule.endOf("day").format(this.FULL_DATE_TIME_FORMAT_MOMENT)
         + '&court_id=' + this.activeCourt.id;
        data = await this.$store.dispatch("schedules/getSchedules", params);
      }
      if (data.error) {
        this.$refs.modalMessage.open(data.message, "Error");
      } else {
        await this.customSchedules(data);
      }
      this.waitLoadingData = false;
    },
    async customSchedules(dataSchedules) {
      let vm = this;
      let daysBetween = this.getDaysBetween(this.start_date_schedule, this.end_date_schedule);
      await vm.$store.dispatch("schedules/customDataSchedules", { dataSchedules: dataSchedules, daysBetween: daysBetween });
    },
    checkDisabledEdit() {
      let start_date = moment(
        moment(this.scheduleData.date).format(this.DATE_FORMAT_SCHEDULE) +  " " + this.scheduleData.start_time + ":00");
      let end_date = 
        moment(moment(this.scheduleData.date).format(this.DATE_FORMAT_SCHEDULE) +  " " + this.scheduleData.end_time + ":00");
      if (start_date <= moment() && end_date > moment()) {
        return "disable-schedule";
      }
      return "";
    },
    checkTimeInvalid(data) {
      let start_time = moment(data.start_time);
      let end_time = moment(data.end_time);
      if (!data.start_time || !data.end_time || !data.mode) {
        this.$refs.modalMessage.open( "Please enter a valid start time, end time, and dimming mode.", "Error");
        return false;
      }
      if (start_time >= end_time) {
        this.$refs.modalMessage.open( "End Time is earlier than Start Time.", "Error");
        return false;
      } else if (end_time <= moment()) {
        this.$refs.modalMessage.open( "The end time must be greater than now.", "Error");
        return false;
      } else if (data.id && end_time <= moment()) {
        this.$refs.modalMessage.open("Schedule has been completed. Can not change!", "Error");
        return false;
      }
      return true;
    },
    resetForm(clearCourt = false) {
      this.scheduleData.id = null;
      this.scheduleData.start_time = "";
      this.scheduleData.end_time = "";
      this.scheduleData.currentMode = null;
      this.scheduleData.status = 'New';
      this.activeNew = false;
      if (moment(this.scheduleData.date) < moment()) {
        this.date = moment().format(this.DATE_FORMAT_SCHEDULE);
      }
      if (clearCourt) {
        this.activeCourt = null;
      }
      this.cancelAction();
    },
    createNewSchedule() {
      this.activeNew = true;
      this.scheduleData.status = 'New';
      this.scheduleData.currentMode = this.activeCourt.modes ? this.activeCourt.modes[0] : null;
      if (moment(this.scheduleData.date).format(this.DATE_FORMAT_SCHEDULE) == moment().format(this.DATE_FORMAT_SCHEDULE)) {
        this.scheduleData.start_time = moment().add(15, 'minutes').format(this.HOUR_FORMAT);
        this.scheduleData.end_time = moment().add(30, 'minutes').format(this.HOUR_FORMAT);
      }
    },
    async addSchedule(data) {
      this.resetForm();
      await scheduleService.storeSchedule(data).then((res) => {
          if (res.status !== 200 || (res.data && !res.data.success)) throw res;
          this.$refs.modalMessage.open(res.data.message, "Create Schedule");
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            this.$refs.modalMessage.open(err.response.data.message, "Error");
          }
        });
      await this.getListSchedules();
    },
    async getSchedulesInDay(date) {
      let schedulesInDay = [];
      if (this.activeCourt && this.activeCourt.id) {
        let params = "?start_time=" + date.clone().startOf("day").format(this.FULL_DATE_TIME_FORMAT_MOMENT) +
          "&end_time=" + date.clone().endOf("day").format(this.FULL_DATE_TIME_FORMAT_MOMENT)
          + "&court_id=" + this.activeCourt.id;
        await scheduleService.getListSchedules(params).then((res) => {
          if (res.status == 200 && res.data.success) {
            schedulesInDay = res.data.data;
          }
        });
      }
      return schedulesInDay;
    },
    checkInvalidData() {
      let invalid = [];
      if (!this.scheduleData.date) {
        invalid.push("Schedule date is required");
      }
      if (!this.scheduleData.start_time) {
        invalid.push("Schedule start time is required");
      }
      if (!this.scheduleData.end_time) {
        invalid.push("Schedule end time is required");
      }
      if (!this.scheduleData.currentMode) {
        invalid.push("Schedule mode is required");
      }
      if (invalid.length > 0) {
        this.$refs.modalMessage.open(invalid.join("<br>"), "Data Invalid");
        return false;
      }
      return true;
    },
    async saveSchedule() {
      if (this.checkInvalidData()) {
        let _date = moment(this.scheduleData.date);
        let data = {
          court_id: this.activeCourt.id,
          date: _date.format(this.DATE_FORMAT_SCHEDULE),
          start_time: _date.format(this.DATE_FORMAT_SCHEDULE) + " " + this.scheduleData.start_time + ":00",
          end_time: _date.format(this.DATE_FORMAT_SCHEDULE) + " " + this.scheduleData.end_time + ":00",
          mode: this.scheduleData.currentMode,
        };

        if (this.scheduleData.status !== 'New' && this.scheduleData.id) {
          data.id = this.scheduleData.id;
        }
        if (this.checkTimeInvalid(data)) {
          let schedulesInDay = await this.getSchedulesInDay(moment(data.date));
          let _e_start = moment(data.start_time);
          let _e_end = moment(data.end_time);
          let overlap = this.checkOverLapSchedules(data.id, _e_start, _e_end, schedulesInDay);
          if (!overlap) {
            if (this.scheduleData.status !== 'New' && data.id) {
              await this.update(data);
            } else {
              await this.addSchedule(data);
            }
          }
        }
      }
    },
    async update(data) {
      this.resetForm();
      await scheduleService.updateSchedule(data.id, data).then((res) => {
          if (res.status !== 200 || (res.data && !res.data.success)) throw res;
          this.$refs.modalMessage.open(res.data.message, "Update Schedule");
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            this.$refs.modalMessage.open(err.response.data.message, "Error");
          }
        });
      await this.getListSchedules();
    },
    showModelRemove() {
      let message = 'Are you sure you want to delete this schedule?';
      let start_ = moment(this.scheduleData.checkMyStart);
      let end_ = moment(this.scheduleData.checkMyEnd);
      if (this.NOW_DATE().isBetween(start_, end_)) {
        message = "The schedule is currently on-going. <br> Deleting schedule will turn off the lighting. <br> Do you wish to continue?";
      }
      this.messageDialogRemoveSchedule = message;
      this.showModalRemove = true;
    },
    async removeSchedule() {
      await scheduleService.deleteSchedule(this.scheduleData.id).then((res) => {
          if (res.status !== 200 || (res.data && !res.data.success)) throw res;
          this.$refs.modalMessage.open(res.data.message, "Success");
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            this.$refs.modalMessage.open(err.response.data.message, "Error");
          }
        });
      await this.getListSchedules();
      this.resetForm();
      this.showModalRemove = false;
    },
    getDimensions() {
      let responsive_Court = document.getElementById("responsive_Court");
      if (responsive_Court && responsive_Court.offsetWidth) {
        let ratio_width = 610 / 66;
        this.width_btn_court = responsive_Court.offsetWidth / ratio_width;
        if (
          (window.innerWidth < 1857 && window.innerWidth > 1470) ||
          (window.innerWidth < 1250 && window.innerWidth > 1000)
        ) {
          this.width_btn_court = this.width_btn_court - 5;
        }
      }
      this.height_row = this.getHeightRowGranttChart();
    },
    eventClickBarMenu(e) {
      if (e && e.bar) {
        let data = JSON.parse(JSON.stringify(e.bar));
        let nowD = this.NOW_DATE();
        this.dataScheduleEvent = data;
        if (this.activeCourt && this.scheduleData && (this.activeNew || this.scheduleData.end_time && moment(this.scheduleData.date + " " + this.scheduleData.end_time) > nowD)) {
          this.activeClick = 'schedule-db-click';
          this.showModalContinuate = true
        } else {
          this.clickBarMenu();
        }
      }
    },
    clickBarMenu() {
      let data = this.dataScheduleEvent;
      let nowD = this.NOW_DATE();
      let _my_start = moment(data.start_time);
      let _my_end = moment(data.end_time);
      this.scheduleData = data;
      this.scheduleData.date = moment(data.date);
      this.scheduleData.start_time = moment(data.myStart).format(this.HOUR_FORMAT);
      this.scheduleData.end_time = moment(data.myEnd).format(this.HOUR_FORMAT);
      this.scheduleData.currentMode = data.mode;
      let court = this.courts.filter((item) => { return item.id == data.court_id; });
      this.scheduleData.modes = court[0].modes;
      this.activeCourt = court[0];
      this.date = moment(data.date).format(this.DATE_FORMAT_SCHEDULE);
      if (_my_end > nowD) {
        if (nowD.isBetween(_my_start, _my_end)) {
          this.scheduleData.status = "On-going";
        } else if (_my_start > nowD) {
          this.scheduleData.status = "Pending";
        }
      } else {
        this.scheduleData.status = "Completed";
      }
      this.dataScheduleEvent = null;
    },
    checkOverLapSchedules(id, start, end, dataRow = []) {
      let overlap = false;
      for (let bar of dataRow) {
        if ((id && bar.id != id) || !id) {
          let _bar_start = moment(bar.myStart ?? bar.start_time);
          let _bar_end = moment(bar.myEnd ?? bar.end_time);
          if (
            (_bar_start <= start && _bar_start >= end) ||
            (_bar_end >= start && _bar_end <= end) ||
            (start >= _bar_start && start <= _bar_end) ||
            (end >= _bar_start && end <= _bar_end)
          ) {
            overlap = true;
            break;
          }
        }
      }
      if (overlap) {
        this.$refs.modalMessage.open("There is already an existing schedule during the period you entered.", "Warning");
      }
      return overlap;
    },
    eventDragBar(e) {
      let data = JSON.parse(JSON.stringify(e.bar));
      this.dataScheduleEvent = data;
      let nowD = this.NOW_DATE();
      if (this.activeNew || this.scheduleData && this.scheduleData.end_time && moment(this.scheduleData.date + " " + this.scheduleData.end_time) > nowD) {
        this.activeClick = 'schedule-drag';
        this.showModalContinuate = true
      } else {
        this.stoppedDraggingBar();
      }
    },
    async stoppedDraggingBar() {
      let data = this.dataScheduleEvent;
      let indexRow = this.dataSchedules.findIndex((item) => {
        return item.date == data.date;
      });
      let start_now = moment().startOf("day");
      let end_now = moment().endOf("day");
      let _e_start = moment(data.myStart);
      let _e_end = moment(data.myEnd);
      let overTime = this.checkOverTime(_e_start, _e_end, start_now, end_now);
      let indexBar = this.dataSchedules[indexRow].bars.findIndex((item) => {
        return item.id == data.id;
      });
      if (overTime) {
        if (_e_start < start_now) {
          _e_start = start_now;
        }
        if (_e_end > end_now) {
          _e_end = end_now;
        }
        let overlap = this.checkOverLapSchedules(data.id, _e_start, _e_end, this.dataSchedules[indexRow].bars);
        if (overlap) {
          this.dataSchedules[indexRow].bars[indexBar].myStart =
            _e_start.format(this.DATE_FORMAT_SCHEDULE) + " " + moment(data.start_time).format(this.HOUR_FORMAT);
          this.dataSchedules[indexRow].bars[indexBar].myEnd =
            _e_end.format(this.DATE_FORMAT_SCHEDULE) + " " + moment(data.end_time).format(this.HOUR_FORMAT);
        } else {
          let overTime = this.checkOverTime(_e_start, _e_end, start_now, end_now);
          let dataUpdate = {
            id: data.id,
            court_id: data.court_id,
            date: data.date,
            start_time: data.date + " " + _e_start.format(this.HOUR_FORMAT) + ":00",
            end_time: data.date + " " + _e_end.format(this.HOUR_FORMAT) + ":00",
            mode: data.mode,
          };
          let timeInvalid = this.checkTimeInvalid(dataUpdate);
          if (overTime && timeInvalid) {
            this.dataSchedules[indexRow].bars[indexBar].myStart =
              _e_start.format(this.DATE_TIME_HOUR_FORMAT_MOMENT);
            this.dataSchedules[indexRow].bars[indexBar].myEnd = _e_end.format(
              this.DATE_TIME_HOUR_FORMAT_MOMENT
            );
            let style = this.getStyleBar(dataUpdate);
            this.dataSchedules[indexRow].bars[indexBar].ganttBarConfig.color =
              style.color;
            this.dataSchedules[indexRow].bars[
              indexBar
            ].ganttBarConfig.backgroundColor = style.backgroundColor;
            await this.update(dataUpdate);
          } else {
            this.setTimeBar(indexRow, indexBar, start_now, end_now, data.start_time, data.end_time);
          }
        }
      } else {
        this.setTimeBar(indexRow, indexBar, start_now, end_now, data.start_time, data.end_time);
      }
    },
    setTimeBar(indexRow, indexBar, start_now, end_now, start_time, end_time) {
      this.dataSchedules[indexRow].bars[indexBar].myStart = start_now.format(this.DATE_FORMAT_SCHEDULE) + " " + moment(start_time).format(this.HOUR_FORMAT);
      this.dataSchedules[indexRow].bars[indexBar].myEnd = end_now.format(this.DATE_FORMAT_SCHEDULE) + " " + moment(end_time).format(this.HOUR_FORMAT);
    },
    checkOverTime(start_time, end_time, start_now, end_now) {
      if ((start_time < start_now && end_time < start_now) || (start_time > end_now && end_time > end_now)) {
        this.$refs.modalMessage.open("The schedule cannot be changed to another day!", "Warning");
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped>
.v-text-field__details {
  display: none;
}
.g-gantt-bar-handle-right {
  background: none !important;
}
</style>
