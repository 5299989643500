<template>
  <v-dialog
    v-model="messageDialog"
    :max-width="width"
    content-class="profile-confirm-dialog"
  >
    <v-card id="profile-confirm">
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text
        ><v-row no-gutters>
          <v-col cols="12" style="padding-top: 6px">
            <div class="text-center" v-html="message"></div>
          </v-col>
          <v-col cols="12 pt-4" class="text-center">
            <button class="ml-4 btn btn-primary btn-custom" :ripple="false" @click="closeMessage">OK</button>
          </v-col>
        </v-row></v-card-text
      >
    </v-card>
  </v-dialog>
</template>

<script>
export default {
    name: "ModalMessage",
    data() {
      return {
        messageDialog: false,
        message: "",
        title: "",
        width: "700"
      }
    },
    methods: {
      closeMessage() {
        this.messageDialog = false;
        this.message = "";
        this.title = "";
      },
      open(message, title, width = "700") {
        this.messageDialog = true;
        this.message = message;
        this.title = title;
        this.width = width;
      }
    }
}
</script>